<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-margin-file-per pl-8 pr-8">
      <v-row class="mt-11">
        <v-col class="text-left" cols="6">
          <h1 class="report-percentage__title">
            Registro de firmas por porcentaje
          </h1>
        </v-col>
        <v-col cols="3">
          <p>
            <v-btn
              @click="download()"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
              class="mt-3"
            >
              Descargar lista
              <!-- Icono -->
            </v-btn>
          </p>
        </v-col>
        <v-col cols="3">
          <v-btn
            :to="{ name: 'reportSign' }"
            block
            dark
            rounded
            color="#466be3"
            class="report-percentage__botton mt-3"
          >
            Ver estatus por usuario
          </v-btn>
        </v-col>
      </v-row>
      <!-- Filtros -->
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="form.clientId"
            :items="listClient"
            label="Selecciona el cliente"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="form.campaignId"
            :items="listCampaign"
            label="Selecciona la campaña"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-1 table-report-header report-percentage-margin__table"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
          >
            <!-- Sin resultados de busqueda -->
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            <!-- Sin Data -->
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <!-- Loading -->
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <!-- Porcentajes -->
            <template v-slot:item.acceptancePercentage="{ item }">
              <v-layout justify-start>
                <v-progress-linear
                  style="width: 75%"
                  rounded
                  :value="item.acceptancePercentage"
                  color="#466be3"
                  height="12"
                  class="mt-1"
                >
                </v-progress-linear>
                <p class="mb-0 ml-3" style="width: 25%">
                  {{ item.acceptancePercentage }}%
                </p>
              </v-layout>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-margin-file-per">
      <v-row justify="center" class="ml-0 mr-0 px-3 my-5">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            El registro de firmas por porcentaje
          </p>
        </v-col>

        <v-col cols="12" class="py-3 px-2">
          <v-btn
            :to="{ name: 'reportSign' }"
            block
            dark
            rounded
            color="#466be3"
          >
            <strong> Ver estatus por usuario </strong>
          </v-btn>
        </v-col>

        <v-col cols="12" class="py-0 px-2">
          <p>
            <v-btn
              @click="download()"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
            >
              <strong> Descargar lista </strong>
              <!-- Icono -->
            </v-btn>
          </p>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.clientId"
            :items="listClient"
            label="Selecciona el cliente"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.campaignId"
            :items="listCampaign"
            label="Selecciona la campaña"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <!-- 
        SE COMENTA ESTO PORQUE ESTA TABLA NO VA A ESTAR DISPONIBLE EN VERSION MOBILE
        <v-col cols="12" class="py-0 px-2">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            style="background-color: #f9f9f900"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-0 table-report-header"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
          >
            ** Sin resultados de busqueda
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            ** Sin Data
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            ** Loading
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            ** Porcentajes
            <template v-slot:item.acceptancePercentage="{ item }">
              <v-layout justify-start>
                <v-progress-linear
                  style="width: 75%"
                  rounded
                  :value="item.acceptancePercentage"
                  color="#466be3"
                  height="12"
                  class="mt-1"
                >
                </v-progress-linear>
                <p class="mb-0 ml-3">{{ item.acceptancePercentage }}%</p>
              </v-layout>
            </template>
          </v-data-table>
        </v-col> -->
      </v-row>
    </div>

    <!-- DIALOG -->
    <!-- <v-dialog v-model="message.dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 request-project">
          <v-layout justify-center>
            {{ message.title }}
          </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project">
          {{ message.sms }}
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  name: "upload",
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      listClient: [],
      listCampaign: [],
      loading: {
        table: false,
        all: false,
      },
      form: {
        clientId: null,
        campaignId: null,
      },
      items: [],
      headers: [
        {
          text: "Cliente",
          value: "client",
          class: "report-percentage-margin__header",
        },
        {
          text: "Campaña",
          value: "campaign",
          class: "report-percentage-margin__header",
        },
        {
          text: "Perfil",
          value: "profile",
          class: "report-percentage-margin__header",
        },
        {
          text: "Acepta",
          value: "accepted",
          class: "report-percentage-margin__header",
        },
        {
          text: "No acepta",
          value: "notAccepted",
          class: "report-percentage-margin__header",
        },
        {
          text: "Total general",
          value: "totalEmployees",
          class: "report-percentage-margin__header",
        },
        {
          text: "% de aceptación",
          value: "acceptancePercentage",
          class: "report-percentage-margin__header",
        },
      ],
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
    };
  },
  watch: {
    "form.clientId": function (n) {
      this.dinamicFilter();
      this.getReportPercentage();
    },
    "form.campaignId": function (n) {
      this.dinamicFilter();
      this.getReportPercentage();
    },
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getClients() {
      Api.MarginCommisions()
        .getClients(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listClient = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCampaigns() {
      Api.MarginCommisions()
        .getCampaigns(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listCampaign = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportPercentage() {
      var data = {
        clientId:
          this.form.clientId != null ? parseInt(this.form.clientId) : null,
        campaignId:
          this.form.campaignId != null ? parseInt(this.form.campaignId) : null,
      };
      this.items = [];
      this.loading.table = true;
      Api.MarginCommisions()
        .getReportPercentage(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.items = res.data.data;
            for (let i = 0; i < this.items.length; i++) {
              this.items[i].acceptancePercentage = this.items[
                i
              ].acceptancePercentage.substring(
                0,
                this.items[i].acceptancePercentage.length - 1
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.dialog = true;
          this.message.sms = error.response.data.message;
          this.message.title = "¡Oh no!";
          this.message.type = "error";
          // setTimeout(() => {
          //   this.message.dialog = false;
          // }, 3000);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    download() {
      var data = {
        clientId:
          this.form.clientId != null ? parseInt(this.form.clientId) : null,
        campaignId:
          this.form.campaignId != null ? parseInt(this.form.campaignId) : null,
      };
      this.loading.all = true;
      Api.MarginCommisions()
        .getReportPercentageDownload(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            // setTimeout(() => {
            //   this.message.dialog = false;
            // }, 3000);
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.dialog = true;
          this.message.sms = error.response.data.message;
          this.message.title = "¡Oh no!";
          this.message.type = "error";
          // setTimeout(() => {
          //   this.message.dialog = false;
          // }, 3000);
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    dinamicFilter() {
      this.loadingAll = true;
      let data = {
        clientId: this.form.clientId,
        campaignId: this.form.campaignId,
      };
      Api.MarginCommisions()
        .dinamicFilter(data, this.token)
        .then((res) => {
          if (res.data.cod === 0) {
            this.listClient = [];
            this.listCampaign = [];
            this.listProfile = [];
            this.listContractor = [];
            res.data.data.forEach((element) => {
              let client = {
                id: element.client_id,
                name: element.client_name.toUpperCase(),
              };
              this.listClient.push(client);
              let campaign = {
                id: element.campaign_id,
                name: element.campaign_name.toUpperCase(),
              };
              this.listCampaign.push(campaign);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    // this.getClients();
    // this.getCampaigns();
    this.getReportPercentage();
    this.dinamicFilter();
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";
.mb-xl-margin-file-per {
  display: block;
}

.mb-sm-margin-file-per {
  display: none;
}

.txt-title-report {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  color: #353535;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.report-percentage-margin__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.report-percentage-margin__header {
  color: #353535 !important;
}

tr,
th,
td,
span .report-percentage-margin__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
}

.report-percentage__title {
  font-family: "RobotoRegular";
  font-weight: 700;
  font-size: 40px;
  line-height: 59px;
  color: #353535;
}

.report-percentage__botton:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin-file-per {
    display: none;
  }
  .mb-sm-margin-file-per {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin-file-per {
    display: none;
  }
  .mb-sm-margin-file-per {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin-file-per {
    display: none;
  }
  .mb-sm-margin-file-per {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin-file-per {
    display: none;
  }
  .mb-sm-margin-file-per {
    display: block;
    text-align: initial;
  }
}
</style>
